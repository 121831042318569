.View {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    background-color: black;
}
.LobbyContents {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    overflow: hidden;
}
.PlayContents {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 0;
    overflow: hidden;
}
.View .Canvas {
    position: absolute;
    top: 0;
    bottom: 0;
}

.EditView {
    background-color: slategray;
}

.PlayView {
    position: absolute;
    top: 0;
}
