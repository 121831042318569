.HudView {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    overflow: hidden;
}

.Hud {
    pointer-events: auto;
}

.Hud .Menu {
    height: 40px;
}
.Hud .logOut {
    position: absolute;
    top: 0;
    right: 0;
}

.Hud .Title {
    width: 200px;
}
