body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
}
#root {
  background: white;
  height: 100%;
}
